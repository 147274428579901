
.findoutmore-img {
    /* <img src="../imagesfromvuejs/findoutmore.png" width="130px" height="30px" >  */
    background: url("../imagesfromvuejs/findoutmore.png") no-repeat center center;
    width:auto;
    height:30px;
    border-radius:60px 
 }
 .placeholder-dashboard{
 width: 160px;
 height: 100px;  
 }
 .m1{
 background: url("../imagesfromvuejs/conferences.png") no-repeat center center;
 margin: 5px 5px 25px 5px;
 border-radius: 50px;
 width: auto;
 height: auto;
 color: white;
 font-weight: bolder;
 }
 .m2{
 background: url("../imagesfromvuejs/entertainment.png") no-repeat center center;
 margin: 5px 5px 25px 5px;
 border-radius: 50px;
 width: auto;
 height: auto;
 color: white;
 font-weight: bolder;
 }
 
 .m3{
 background: url("../imagesfromvuejs/music.png") no-repeat center center;
 margin: 5px 5px 25px 5px;
 border-radius: 50px;
 width: auto;
 height: auto;
 color: white;
 font-weight: bolder;
 }
 
 .m4{
 background: url("../imagesfromvuejs/parties.png") no-repeat center center;
 margin: 5px 5px 25px 5px;
 border-radius: 50px;
 width: auto;
 height: auto;
 color: white;
 background: "leisure";
 font-weight: bolder;
 }
 
 .m5{
 background: url("../imagesfromvuejs/sport.png") no-repeat center center;
 margin: 5px 5px 25px 5px;
 border-radius: 50px;
 width: auto;
 height: auto;
 color: white;
 font-weight: bolder;
 }
 
 .m6{
 background: url("../imagesfromvuejs/fairs.png") no-repeat center center;
 margin: 5px 5px 25px 5px;
 border-radius: 50px;
 width: auto;
 height: auto;
 color: white;
 font-weight: bolder;
 }
 
 .m7{
  background: url("../imagesfromvuejs/trainings.png") no-repeat center center; 
 margin: 5px 5px 25px 5px;
 border-radius: 50px;
 width: auto;
 height: auto;
 color: white;
 font-weight: bolder;
 }
 
 .m8{
 background: url("../imagesfromvuejs/tourism.png") no-repeat center center; 
 margin: 5px 5px 25px 5px;
 border-radius: 50px;
 width: auto;
 height: auto;
 color: white;
 font-weight: bolder;
 }
 
 .m9{
 background: url("../imagesfromvuejs/festivals.png") no-repeat center center; 
 margin: 5px 5px 25px 5px;
 border-radius: 50px;
 width: auto;
 height: auto;
 color: white;
 font-weight: bolder;
 }
 
 .m10{
 background: url("../imagesfromvuejs/leisure.png") no-repeat center center; 
 margin: 5px 5px 25px 5px;
 border-radius: 50px;
 width: auto;
 height: auto;
 color: white;
 font-weight: bolder;
 }
 /* conferences entertainments leisure music parties sport trade trainings */
 .m11{
  background: url("../imagesfromvuejs/customevents.png") no-repeat center center; 
 margin: 5px 5px 25px 5px;
 border-radius: 50px;
 width: auto;
 height: auto;
 color: white;
 font-weight: bolder;
 }

 .m12{
  background: url("../imagesfromvuejs/ceremonies.png") no-repeat center center; 
 margin: 5px 5px 25px 5px;
 border-radius: 50px;
 width: auto;
 height: auto;
 color: white;
 font-weight: bolder;
 }

 .m13{
  background: url("../imagesfromvuejs/creative.png") no-repeat center center; 
 margin: 5px 5px 25px 5px;
 border-radius: 50px;
 width: auto;
 height: auto;
 color: white;
 font-weight: bolder;
 }
 
 .m14{
  background: url("../imagesfromvuejs/spectacle.png") no-repeat center center; 
 margin: 5px 5px 25px 5px;
 border-radius: 50px;
 width: auto;
 height: auto;
 color: white;
 font-weight: bolder;
 }
 
 
 .col1 {
 background: url("../imagesfromvuejs/fonddetoile.png")
 }
 
 .col2 {
 
 }
 .vue-dashboard-container {
 color: goldenrod;
 background-color: antiquewhite;
 border-radius:15px;
 width: 100%;
 padding: 20px 0 0 0
 }
 .a{
   color:black;
   width:auto;
   background-color: aliceblue;
   height:30px;
   text-align: center;
   justify-content: center;
   align-content: center;
   border-radius: 40px;
 }

 .imgb{
   border-radius:50px;
   text-align: center;
   justify-content: center;
   align-content: center;
 }
 
 .justify-content-md-center{
    text-align: center;
    justify-content: center;
    align-content: center;
 }