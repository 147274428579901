.transaction-verification-container{
    background-color:whitesmoke;
    color:goldenrod;
    border-radius:20px;
    align-content: center; 
    width:auto; 
    height: auto;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    text-align: center;
  }