.marveltech-body{
  background-image: linear-gradient(  #808080,white);

}

.carousel-in-body{
  margin-top: 400px;
}
.invoice-save-cancel-panel{
margin-top: 20px;  
}

.invoice-buttons{
  border-radius: 5px;
  justify-content: center;
  border: solid 4px grey;
  background-color:burlywood;
  color: blue
  }

.invoices-container{
  border-radius:50px;
  background-color: silver;
  color: black;
  width: fit-content;
  display: flex;
  justify-content: center;
  font-family: Courier, monospace;
  text-align: left;
  padding-right:20px;
}

.menu-container{
  border-radius:50px;
  color: black;
  width: 50%;  
  display: flex;
  justify-content: center;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-align: left;  
  align-items: center;
  justify-content: center;
  padding: 0 30px 0 20px;
  text-align: center; 
  font-weight: 600;
  margin:auto;
}

/*

 width:auto; 
  height: auto;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  text-align: center;

*/
.invoice-wrapper{
 
}
.text_descriptor_name{
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 5px;
  margin-top: 100px;   
}

.text_descriptor_note{
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 5px;
  margin-top: 10px  
}


  .akwaba-content{ 
  width:100%;
  }

  .akwaba-content-training{ 
    width:100%;
    height: 250px;
    background-color: aqua;
    }

    .dashboard-content-admin{ 
      width:100%;     
      background-color: goldenrod;
      align-items: center;
      justify-content: center;
      text-align: center;      
      }
  
  .akwaba-lang {
    padding: 10px 16px;    
    top: 0;
    width: 100%;    
    background: -webkit-linear-gradient(#eee, #808080); 
  }
  
  .akwaba-container-items {
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 5px;   
  }
  
  
  .akwaba-container-items-adminpage {
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 5px;   
    background-color: white;
    width: 100%;
    list-style-type:none;
  }
  
  .akwaba-tiles-navlinks {
    color: white;  
    font-family: verdana;
    font-size: 16px;
    font-weight: bold;
    width: 50%;
  }
  .akwaba-services-title {
    padding: 10px 10x 10px 10px;
    font-family: verdana;
    font-size: 26px;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .akwaba-tiles {
    flex-direction: column;
    align-items: left;
    justify-content: center;
    text-align: left;
    color: navy;
    border-radius:  5px;
    border: 5px;  
  }
  
  .akwaba-group-tiles{
    flex-direction: column;
    align-items: left;
    justify-content: center;
    text-align: left;
    color: navy;
    border-radius: 5px;
    border:  5px;
    border-color: #808080;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  #signedin{
    color:magenta;
  }

  .loadmore{
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: navy;
    border-radius: 5px;
    border:  5px;
    border-color: #808080;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .reply-link-homepage{
    margin-top: 1rem;
    margin-bottom: 1rem;   
    color: red;
    background-color: white;
    width: 300px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-content: center;
    border-radius: 10px;
    text-align: center;    
    justify-content: center;
    text-decoration:none;
  }

  .home-pic-align{
    background-color: aliceblue;
    width:max-content;
    justify-content: center;
    align-content: center;
    display: flex;
    
  }
  
.margin-maker{
  margin-left: 20px;
}

  .admin-home{
    color: #fff;
    background-color: grey;
    font-family: verdana;
    font-size: 16px;
    font-weight: 700;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .admin-link-to-post{ 
    background-color:white;
    border: solid #e31b50 4px;
    border-radius: 15px;
    justify-content: center;
    text-align: center;
    margin-top: 20px;
    padding: 10 10 10 10;
}

.case-form{
  border: solid cyan 1px;
   border-radius: 10px; 
   color: blue;
    background-color: cyan;
}

button.home-search-button{
  border-radius: 10px; 
   color: indigo;
   background-color: orange;
}

.input[type="text"]:enabled {
  background: khaki;
}

.input:invalid {
  border: 2px solid red;
}

.input:focus {
  background-color: cyan;
}

::-webkit-input-placeholder { /* Edge */
  color:  goldenrod;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color:  goldenrod;
}

::placeholder {
  color: goldenrod;
}

.img.detail-page-carousel-image{ 
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  width: 90%;
  max-width: 700px;
  height: auto;
  display: flex;
  align-content: center; 
  border-left: 75%; 
}

.img.home-page-image{ 
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  width: 100%;
  max-width: 700px;
  max-height:400px;
  display: flex;
  align-content: center; 
  border-left: 75%; 
}

.detailed-item-identification-number{
  color:  goldenrod;
  font-size: large;
  font-weight: bolder;
  align-content: center; 
  justify-content: center;
  text-align: center;
}

.detailed-item-description{
  color:  navy;
  font-size: large;
  font-weight: bolder;
  align-content: center; 
  justify-content: center;
  text-align: center;
}

#dashboard-home-image{
  background:url(../../images/test.main.PNG) no-repeat;
  background-size:     cover;                      /* <------ */
  background-repeat:   no-repeat;
  background-position: center center;
  max-width: 100%;
  min-height: 400px; 
  margin-bottom: 15px;
  opacity: 0.8;
}

#dashboard-home-image:hover{
  opacity: 1;
}

#dashboard-home-1{
color:white;
display: flex;
justify-content: center;
text-align: center;
font-size: 60px;
font-weight:900;
}

#dashboard-home-light-1{
  color:white;
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 40px;
  font-weight:bolder;
  }



#dashboard-home-2{
  color:white;
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 60px;
  font-weight:900;
  }
  
  #dashboard-home-light-2{
    color:white;
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 40px;
    font-weight:bolder;
    }


    #dashboard-home-3{
      color:white;
      display: flex;
      justify-content: center;
      text-align: center;
      font-size: 60px;
      font-weight:900;
      }
      
      #dashboard-home-light-3{
        color:white;
        display: flex;
        justify-content: center;
        text-align: center;
        font-size: 40px;
        font-weight:bolder;
        }

        #dashboard-home-4{
          color:peachpuff;
          display: flex;
          justify-content: center;
          text-align: center;
          font-size: 60px;
          font-weight:900;
          }
          
          #dashboard-home-light-4{
            color:peachpuff;
            display: flex;
            justify-content: center;
            text-align: center;
            font-size: 40px;
            font-weight:bolder;
            }

#col-dashboard-1 {
  border-radius:50px; 
  display: flex;
  justify-content: center; 
  align-items: center;
  justify-content: center; 
  text-align: center; 
  margin:auto;

  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  margin-top: auto;
  /* margin-left:auto; */
  flex-basis: 0;
  flex-grow: 1;
  width:100%;  
  max-width: 100%;
  min-height: 300px;
  border: solid white;
  border-width: 15px;  
  background-color: burlywood;
  background:url(../../images/test.opt1.PNG) no-repeat;
  background-size:     cover;                      /* <------ */
  background-repeat:   no-repeat;
  background-position: center center;   
}

#col-dashboard-2 {
  border-radius:50px; 
  display: flex;
  justify-content: center; 
  align-items: center;
  justify-content: center; 
  text-align: center; 
  margin:auto;

  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  margin-top: auto;
  flex-basis: 0;
  flex-grow: 1;
  width:100%;  
  max-width: 100%;
  min-height: 300px; 
  border: solid white;
  border-width: 15px;  
  background:url(../../images/test.opt2.PNG) no-repeat;
  background-size:     cover;                      /* <------ */
  background-repeat:   no-repeat;
  background-position: center center;  
}

#col-dashboard-3 {
  border-radius:50px; 
  display: flex;
  justify-content: center; 
  align-items: center;
  justify-content: center; 
  text-align: center; 
  margin:auto;

  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  margin-top: auto;
  flex-basis: 0;
  flex-grow: 1;
  width:100%;  
  max-width: 100%;
  min-height: 300px; 
  border: solid white;
  border-width: 15px;  
  background-color:darkkhaki;
  background:url(../../images/test.opt3.PNG) no-repeat;
  background-size:     cover;                      /* <------ */
  background-repeat:   no-repeat;
  background-position: center center;  
}

#col-dashboard-4 { 
  border-radius:50px; 
  display: flex;
  justify-content: center; 
  align-items: center;
  justify-content: center; 
  text-align: center; 
  margin:auto;

  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  margin-top: auto;
  /* margin-right:15px;  */
  flex-basis: 0;
  flex-grow: 1;
  width:100%;  
  max-width: 100%;
  min-height: 300px; 
  border: solid white;
  border-width: 15px;
  
  background-color:antiquewhite;
  background:url(../../images/test.opt4.PNG) no-repeat;
  background-size:     cover;                      /* <------ */
  background-repeat:   no-repeat;
  background-position: center center;  
}



/* img {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  width: 150px;
} */

img:hover {
  box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
}

.home-page-image{ 
}
@media only screen and (max-width: 600px) and (min-width: 400px)  {

}

@media only screen and (min-width: 960px) {
  /* styles for browsers larger than 960px; */
}
@media only screen and (min-width: 1440px) {
  /* styles for browsers larger than 1440px; */
}
@media only screen and (min-width: 2000px) {
  /* for sumo sized (mac) screens */
}
@media only screen and (max-device-width: 480px) {
 /* styles for mobile browsers smaller than 480px; (iPhone) */
}
@media only screen and (device-width: 768px) {
 /* default iPad screens */
}
/* different techniques for iPad screening */
@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
/* For portrait layouts only */
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
/* For landscape layouts only */
}